
export const globalMixin = {
	methods: {
		numdisplay: function (num, dp = 0) {
			if(!num) return num
			else if(isNaN(num)) return num
			return num.toLocaleString('en-US', {minimumFractionDigits: dp, maximumFractionDigits: dp});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			if(num == 0) return 0
			if(!num) return null
			if(this.store.showCommas) return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
			else return num.toFixed(numberOfDecplc);
		},
		numdisplayNoZeroes(num) {
			if(num === 0) return '';
			else return this.numdisplay(num);
		},
		ucfirst: function (str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if (includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		boolToInt: function(boolin) {
			if(boolin === true) return 1;
			return 0;
		},
		trueToYes: function(str) {
			if(str === true) return "Yes";
			return '';
		},
		survDateToNice(yyyymm) {
			if(yyyymm === 0) return '';
			let str = yyyymm.toString();
			let y = str.substring(0,4);
			let m = str.substring(4,6);
			return y+"-"+m;
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		convertToUrlName(str) {
			str = str.replaceAll("/","");
			str = str.replaceAll("'","");
			str = str.replaceAll(' ','-').toLowerCase();
			str = str.replaceAll('--','-').toLowerCase();
			return str;
		},
		stripTags(str) {
			return str.replaceAll('<br/>',' ');
		},
		getMonthName(monthint) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			return months[parseInt(monthint-1)]
		},
		ldzero(intval) {
			if(intval < 10) return '0'+intval.toString()
			return intval.toString()
		},
		dateNow() {
			let d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [year, month, day].join('-');
		},
		getCommonIds(arr1, arr2) {
			let com = []
			for(let id of arr1) {
				if(arr2.find(item => item === id)) {
					com.push(id)
				}
			}
			return com
		},
		haveCommonItems(arr1, arr2) {
			return arr1.some(item => arr2.includes(item))
		},
		removeFromArray(arr, arrayToRemove) {
			for(let i=0; i<arrayToRemove.length; i++) {
				let index = arr.indexOf(arrayToRemove[i])
				if (index > -1) arr.splice(index, 1)
			}
			return arr
		},
	},

}