<template>
	<div>
		<div id="appportal" :class="{iniframe : store.inIframe}">
			<CRAHeader v-if="!store.inIframe" />

			<div class="inner">

				<div v-if="demos.length > 0" class="filters-area">
					<div class="filters-area-inner">
						<div style="margin-right: 10px;">
							<select v-model="selections.datatype" class="select-styled">
								<option v-for="item in availableDatatypes" :key="item.id" :value="item.id">{{item.name}}</option>
							</select>
						</div>
						<FilterItem :label="'Demo ('+countSelectedDemosMain+')'">
							<template v-slot:items>
								<div class="filter-item allerholder">
									<label class="aller" @click="toggleAllDemosMain">All / Clear</label>
								</div>
								<div class="filter-item" v-for="item in demosMain" :key="item">
									<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
								</div>
							</template>
						</FilterItem>
						<FilterItem :label="'Advanced ('+countSelectedDemosAdvanced+')'">
							<template v-slot:items>
								<div class="filter-item allerholder">
									<label class="aller" @click="toggleAllDemosAdvanced">All / Clear</label>
								</div>
								<div class="filter-item" v-for="item in demosAdvanced" :key="item">
									<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
								</div>
							</template>
						</FilterItem>
						<FilterItem :label="'Purch Int ('+countSelectedDemosPurchInt+')'">
							<template v-slot:items>
								<div class="filter-item allerholder">
									<label class="aller" @click="toggleAllDemosPurchInt">All / Clear</label>
								</div>
								<div class="filter-item" v-for="item in demosPurchInt" :key="item">
									<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
								</div>
							</template>
						</FilterItem>
						<FilterItem :label="'Media ('+countSelectedDemosMedia+')'">
							<template v-slot:items>
								<div class="filter-item allerholder">
									<label class="aller" @click="toggleAllDemosMedia">All / Clear</label>
								</div>
								<div class="filter-item" v-for="item in demosMedia" :key="item">
									<label><input v-model="selections.demos" type="checkbox" :value="item.id" />{{item.name}}</label>
								</div>
							</template>
						</FilterItem>
						<div><span class="primary-btn" @click="fetchData">Fetch</span></div>
					</div>
				</div>

				<div class="explanation-area">
					<p>
						<span class="bold">Percentage:</span>
						Indicates the portion of listeners that applies to each demographic category.  Eg: "52% of people who consume Genre Y are female aged 18-49"
					</p>
					<p>
						<span class="bold">Index (Past Month Podcast pop 18+:</span>
						Compares % of a podcast’s audience against the overall Australian monthly podcast listener population (age 18+). Eg: Index 125 "Genre X is 25% more likely than ave monthly podcast listener to do purchase a new car."
					</p>
				</div>

				<div class="results-area">
<!--					<div style="display: flex; justify-content: flex-start; padding: 5px 0;">-->
<!--						<span @click="exportToExcel('genre_indexes')" class="excel-link">-->
<!--							<i class="mdi mdi-file-excel-outline"></i>-->
<!--						</span>-->
<!--					</div>-->
					<div class="results-table-holder">
						<table v-if="demos_used.length > 0" class="altbl" id="genre_indexes">
							<thead>
								<tr>
									<td v-for="(col, ind) in headerrowdata" :key="ind" class="headingtext sortable" @click="sortColumn(ind)" data-f-bold="true">
										<span>{{col}}</span>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, rowindex) in rows" :key="rowindex">
									<td v-for="ind in nDataCols" :key="ind" data-t="n" :class="getCellClass(ind, row[ind])">
										<span>{{this.numdisplay(row[ind], 0)}}</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";
import CRAHeader from "@/components/CRAHeader.vue";
import TableToExcel from "@linways/table-to-excel";
import axios from "axios";
import FilterItem from "@/components/FilterItem.vue";

export default {
	name: "MainPage",
	components: {
		FilterItem,
		CRAHeader

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,
			screenWidth: window.innerWidth,

			isFetching: false,
			availableDatatypes: [
				{id: 'percent', name: 'Percent'},
				// {id: 'indexAusPop', name: 'Index Aus Popn'},
				{id: 'indexPodPop', name: 'Index Podcast Popn'},
			],
			genres: [],
			genreAvgListeners: {},
			demos: [],

			selections: {
				genres: [], //empty is all
				demos: [1,3,8,10,17,34], //empty is all
				datatype: 'percent', //percent, indexAusPop, indexPodPop
			},

			returnedData: {},
			demos_used: [],
			genres_used: [],

			sortColIndex: null,
			sortColOrder: 'desc',
		}
	},
	computed: {
		rows() {
			let rows = []
			for(let genreName of this.genres) {
				let myrow = [
					genreName,
				]
				//get avg listeners if it exists
				if(this.genreAvgListeners[genreName]) {
					myrow.push(this.genreAvgListeners[genreName])
				}
				else myrow.push('')
				for(let demoId of this.demos_used) {
					myrow.push(this.getCellVal(genreName, demoId))
				}
				rows.push(myrow)
			}
			if(this.sortColIndex) {
				let useStrings = false
				if(isNaN(rows[0][this.sortColIndex]) || parseFloat(rows[0][this.sortColIndex]) != rows[0][this.sortColIndex]) useStrings = true;
				if(this.sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[this.sortColIndex].toLowerCase() > b[this.sortColIndex].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[this.sortColIndex] > b[this.sortColIndex]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[this.sortColIndex].toLowerCase() > b[this.sortColIndex].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[this.sortColIndex] > b[this.sortColIndex]) ? -1 : 1)
				}
			}
			return rows;
		},
		demosMain() {
			return this.demos.filter(item => item.cat === 'demo')
		},
		demosAdvanced() {
			return this.demos.filter(item => item.cat === 'advanced')
		},
		demosPurchInt() {
			return this.demos.filter(item => item.cat === 'purchint')
		},
		demosMedia() {
			return this.demos.filter(item => item.cat === 'media')
		},
		countSelectedDemosMain() {
			let c = this.getCommonIds(this.selections.demos, this.demosMain.map(item => item.id))
			return c.length
		},
		countSelectedDemosAdvanced() {
			let c = this.getCommonIds(this.selections.demos, this.demosAdvanced.map(item => item.id))
			return c.length
		},
		countSelectedDemosPurchInt() {
			let c = this.getCommonIds(this.selections.demos, this.demosPurchInt.map(item => item.id))
			return c.length
		},
		countSelectedDemosMedia() {
			let c = this.getCommonIds(this.selections.demos, this.demosMedia.map(item => item.id))
			return c.length
		},
		headerrowdata() {
			let h = ['Genre','Avg Mth Listeners']
			for(let demoId of this.demos_used) {
				let myob = this.demos.find(item => item.id === demoId)
				if(myob) h.push(myob.name)
			}
			return h
		},
		nDataCols() {
			if(this.rows && this.rows[0]) {
				let r = []
				let count = 0
				while(count < this.rows[0].length) {
					r.push(count)
					count++
				}
				return r
			}
			return []
		},
	},
	methods: {
		getMeta() {
			let self = this
			let targ = "/post/genre-meta.php"
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else {
					if(ret.genres) self.genres = ret.genres
					if(ret.genre_avg_mth_listeners) self.genreAvgListeners = ret.genre_avg_mth_listeners
					if(ret.demos) self.demos = ret.demos
				}
				self.isFetching = false
				self.fetchData()
			}).catch(function (error) {
				console.log(error)
				self.isFetching = false
			});
		},
		fetchData() {
			let self = this
			let targ = "/post/fetch-genre-index-table.php"
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				selections: self.selections,
			})).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.table) self.returnedData = ret.table
				if (ret.demos_used) self.demos_used = ret.demos_used
				self.isFetching = false
			}).catch(function () {
				self.isFetching = false
			});
		},
		toggleAllDemosMain() {
			if(this.haveCommonItems(this.selections.demos, this.demosMain.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosMain.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosMain.map(item => item.id))
		},
		toggleAllDemosAdvanced() {
			if(this.haveCommonItems(this.selections.demos, this.demosAdvanced.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosAdvanced.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosAdvanced.map(item => item.id))
		},
		toggleAllDemosPurchInt() {
			if(this.haveCommonItems(this.selections.demos, this.demosPurchInt.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosPurchInt.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosPurchInt.map(item => item.id))
		},
		toggleAllDemosMedia() {
			if(this.haveCommonItems(this.selections.demos, this.demosMedia.map(item => item.id))) {
				this.selections.demos = this.removeFromArray(this.selections.demos, this.demosMedia.map(item => item.id))
			}
			else this.selections.demos = this.selections.demos.concat(this.demosMedia.map(item => item.id))
		},
		exportToExcel(tblid, filename = 'export') {
			if(this.excelfilename && this.excelfilename.length > 0) {
				filename = this.excelfilename;
			}
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			});
		},
		sortColumn(colind) {
			if(this.sortColIndex === colind) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc';
				else this.sortColOrder = 'asc'
			}
			else this.sortColIndex = colind; //either no column yet chosen or a different column, sort by that col
		},
		getCellVal(genreName, demoId) {
			let myval = this.returnedData[genreName][demoId]
			if(myval) return myval
			return ''
		},
		getCellClass(colind, val) {
			let classes = []
			if(colind > 0) classes.push('aright')
			if(val > 100 && colind > 1) classes.push('highlight')
			return classes.join(' ')
		},
	},
	watch: {

	},
	mounted() {
		this.getMeta()
	},
	created() {
		let queryString = window.location.search
		let urlParams = new URLSearchParams(queryString)
		if(urlParams.has('iframe')) {
			let isIframe = urlParams.get('iframe')
			if(isIframe === '1' || isIframe === 'true' || isIframe === true) {
				this.store.inIframe = true
			}
		}
	},
}
</script>
<style scoped>
#appportal {
	min-height: 100vh;
	background-color: #F9F9F9;
}
#appportal.iniframe {
	background-color: #FFF;
}
.filters-area {
	padding-top: 10px;
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
}
.filters-area-inner {
	padding-top: 10px;
	display: flex;
	align-items: flex-end;
}
.explanation-area {
	margin-bottom: 20px;
	max-width: 800px;
}
.explanation-area p {
	margin-bottom: 5px;
	font-size: 12px;
}
.bold {
	font-weight: bold;
}

@media screen and (max-width: 935px) {
	.filters-area-inner {
		flex-wrap: wrap;
		gap: 10px;
	}
}

@media screen and (max-width: 800px) {

}

@media screen and (max-width: 600px) {

}
</style>