<template>
	<div class="header">
		<div class="inner">
			<div class="logo-hold">
				<img src="@/assets/cra-logo-white.png" alt="Commercial Radio & Audio logo" />
			</div>
		</div>
	</div>
</template>
<script>
import {globalMixin} from "@/mixins";
import {store} from "@/store";

export default {
	name: "CRAHeader",
	components: {

	},
	mixins: [globalMixin],
	data() {
		return {
			store: store,

		}
	},
	computed: {

	},
	methods: {

	},
	watch: {

	},
	mounted() {

	},
	created() {

	},
}
</script>
<style scoped>
.header {
	height: 70px;
	background: transparent linear-gradient(270deg,  #E81F76 0%,  #00AEEF 100%) 0% 0% no-repeat padding-box;
	display: flex;
	align-items: center;
}
.header .logo-hold {
	height: 50px;
	display: flex;
	align-items: center;
}
.header .logo-hold img {
	height: 100%;
}
</style>